import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import context from '../../core/context';
import CustomEvents, { Events } from '../../core/CustomEvents';
import Link from '../../core/PageSwitch/Link';
import CustomOverDetection from '../../core/utils/CustomOverDetection';
import { getWord } from '../../core/utils/Language';
import Functions from '../../style/Functions';
import { Breakpoints, Colors, Fonts, Index } from '../../style/Variables';
import CommonWords from '../../types/CommonWords';
import FooterInformation from '../Footer/FooterInformation';
import LogoViewer from '../LogoViewer';
import gsap from 'gsap';

// styles
const MenuContainer = styled.div`
	position: fixed;
	top: 0;
	box-sizing: border-box;
	opacity: 0;
	left: 0;
	width: 100%;
	height: 0;
	background-color: ${Colors.white};
	z-index: ${Index.fullScreenMenu};
`;

const MenuTopNav = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	justify-items: center;
	gap: var(--gridGutter);
	padding: 0 var(--gridMargin);

	width: 100%;
	height: 60px;
	background-color: white;
	border-bottom: 1px solid ${Colors.black};

	font-family: ${Fonts.sprittenFamily};
	font-size: var(--fontNotQuiteMedium);
	${Functions.breakpoint(Breakpoints.mobile)} {
		border-bottom: 1px solid ${Colors.black};
		height: 80px;
	}
`;

const LogoLink = styled(Link)`
	transition: background-color 0.1s linear 0s;
	display: flex;
	${Functions.breakpoint(Breakpoints.mobile)} {
		${Functions.slideInHover(Colors.yellow)}

		&:focus,
	:hover {
			/* background-color: ${Colors.yellow}; */
			color: ${Colors.black};
		}
	}
`;

const LogoImage = styled.img`
	width: 36px;
	height: 36px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		width: 50px;
		height: 50px;
	}
`;

const CloseMenu = styled.div`
	font-family: ${Fonts.sprittenFamily};
	font-size: var(--fontNotQuiteMedium);
	margin-left: auto;
	${Functions.breakpoint(Breakpoints.mobile)} {
		${Functions.slideInHover(Colors.yellow)}

		&:focus,
		:hover {
			/* background-color: ${Colors.yellow}; */
			color: ${Colors.black};
		}
	}
`;

const CloseText = styled.span`
	display: inline-block;
	z-index: 1;
	position: relative;
	margin: 15px 0px;
	transition: background-color 0.4s ease 0s;
	:hover {
		background-color: ${Colors.yellow};
	}
	${Functions.breakpoint(Breakpoints.mobile)} {
		margin: 0;
		font-size: var(--fontNotQuiteMedium);
	}
`;

const MenuContent = styled.div`
	position: relative;
	display: flex;
	fkex-direction: column;
	align-items: flex-start;
	background-color: ${Colors.white};
`;

const ContentContainer = styled.div`
	margin-top: 60px;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 80px;
	align-items: flex-start;
	padding-top: 40px;
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: black;
	overscroll-behavior: contain;
	::-webkit-scrollbar {
		width: 0.2em;
	}
	::-webkit-scrollbar-track {
		background: transparent;
		&:hover {
			background: ${Colors.grey};
			background-clip: padding-box;

			border: 1px solid transparent;
			border-radius: 1em;
		}
	}
	::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.3);
		border-radius: 1em;
	}
	padding-bottom: 80px;

	${Functions.breakpoint(Breakpoints.mobile)} {
		margin-top: 80px;
		padding-top: 80px;
		padding-bottom: 40px;
	}
`;

const MenuList = styled.div`
	flex: 1;
	width: 100%;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 40px;
	padding: 0 var(--gridMargin);
	${Functions.breakpoint(Breakpoints.mobile)} {
		grid-template-columns: repeat(5, 1fr);
		gap: 100px;
	}
`;

const MenuItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	${Functions.breakpoint(Breakpoints.mobile)} {
		gap: 20px;
	}
`;

const MenuItemLink = styled(Link)`
	font-family: ${Fonts.monoFamily};
	color: ${Colors.black};
	font-size: 16px;
	font-weight: 500;
	transition: background-color 0.25s;
	text-transform: uppercase;
	word-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	lineclamp: 2;
	:hover {
		background-color: ${Colors.yellow};
	}
`;

const SubMenuItem = styled(Link)`
	font-family: ${Fonts.monoFamilyLight};
	font-size: 14px;
	font-weight: 400;
	transition: background-color 0.25s;
	:hover {
		background-color: ${Colors.yellow};
	}
	word-wrap: break-word;
	overflow: hidden;
	text-overflow: ellipsis;
	${Functions.breakpoint(Breakpoints.tablet)} {
		font-size: var(--fontDefaultSize);
	}
`;

const Footer = styled(FooterInformation)`
	width: 100%;
	margin-top: 80px;
	z-index: ${Index.topMenu};
`;

export interface MenuProps {
	toggleMenu: boolean;
	updateParentToggle: Function;
}

const FullScreenMenu = (props: MenuProps) => {
	const { state } = useContext(context);
	const MenuContainerRef = useRef<HTMLDivElement>();
	const primaryLinkRefs = useRef(state.topMenu.links.map(() => useRef()));
	const CloseMenuRef = useRef<HTMLDivElement>();
	const [navigationToggle, setNavigationToggle] = useState(false);
	const [isMobile, setIsMobile] = useState(false);

	const closeMenu = () => {
		setNavigationToggle(false);
	};

	// change height menu
	const toggleNavigationMenu = () => {
		if (navigationToggle) {
			MenuContainerRef.current.style.display = null;
			MenuContainerRef.current.style.zIndex = Index.openFullScreenMenu.toString();
			CustomOverDetection.Instance.add(CloseMenuRef.current, mouseOver, mouseOut, () => {}, Index.fullScreenMenu);

			gsap.to(MenuContainerRef.current, {
				opacity: 1,
				duration: 0.5,
				overwrite: true,
				ease: 'power4.inOut',
			});
		} else {
			gsap.to(MenuContainerRef.current, {
				opacity: 0,
				duration: 0.5,
				overwrite: true,
				onComplete: () => {
					MenuContainerRef.current.style.display = 'none';
					MenuContainerRef.current.style.zIndex = '0';
				},
				ease: 'power4.inOut',
			});
		}
	};

	const mouseOver = () => {
		CustomEvents.dispatch(Events.HIGHLIGHTCURSOR, {});
	};

	const mouseOut = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR);
	};

	const onBreakpointChange = () => {
		if (window.innerWidth <= parseInt(Breakpoints.mobile)) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	const mouseOverStandard = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR, {});
	};

	const handleEscape = event => {
		if (event.key === 'Escape') {
			setNavigationToggle(false);
		}
	};

	useEffect(() => {
		onBreakpointChange();
		CustomOverDetection.Instance.add(MenuContainerRef.current, mouseOverStandard, mouseOut, () => {}, Index.link);
		CustomEvents.listen(Events.RESIZE, onBreakpointChange);
		CustomEvents.listen(Events.SAMEPAGE, closeMenu);

		window.addEventListener('keydown', handleEscape);

		return () => {
			CustomOverDetection.Instance.remove(MenuContainerRef.current);
			CustomOverDetection.Instance.remove(CloseMenuRef.current);
			CustomEvents.remove(Events.RESIZE, onBreakpointChange);
			CustomEvents.remove(Events.SAMEPAGE, closeMenu);

			window.removeEventListener('keydown', handleEscape);
		};
	}, []);

	useEffect(() => {
		if (props.toggleMenu) {
			setNavigationToggle(props.toggleMenu);
		}
	}, [props.toggleMenu]);

	useEffect(() => {
		toggleNavigationMenu();
		if (!navigationToggle) {
			props.updateParentToggle(navigationToggle);
		}
	}, [navigationToggle]);

	return (
		<MenuContainer ref={MenuContainerRef}>
			<MenuContent>
				<MenuTopNav>
					<LogoLink to='/'>
						<LogoViewer />
					</LogoLink>
					<CloseMenu ref={CloseMenuRef} onClick={e => setNavigationToggle(false)}>
						<CloseText>{getWord(CommonWords.close)}</CloseText>
					</CloseMenu>
				</MenuTopNav>
				<ContentContainer>
					<MenuList>
						{state.topMenu.links.map((link, i) => (
							<MenuItem key={`main-item-${i}`} ref={primaryLinkRefs.current[i]}>
								<MenuItemLink data={link.primaryLink}>{link.primaryLink.title}</MenuItemLink>
								{link.subLinks.length > 0 &&
									link.subLinks.map((subLink, io) => (
										<SubMenuItem data={subLink} key={`sub-menu-${io}`}>
											{subLink.title}
										</SubMenuItem>
									))}
							</MenuItem>
						))}
					</MenuList>
					<Footer longFormat={false} copyright={true} />
				</ContentContainer>
			</MenuContent>
		</MenuContainer>
	);
};

export default FullScreenMenu;
