import React from 'react';
import { ReactElement, useEffect, useRef } from 'react';
import CustomEvents, { Events } from '../../core/CustomEvents';
import CustomOverDetection from '../../core/utils/CustomOverDetection';

interface SimpleButtonProps {
	children: ReactElement;
	onClick?: () => void;
}

const SimpleButton = (props: SimpleButtonProps) => {
	const { children, onClick } = props;
	const buttonRef = useRef();

	useEffect(() => {
		CustomOverDetection.Instance.add(buttonRef.current, mouseOver, mouseOut, () => {}, 999);
		return () => {
			CustomOverDetection.Instance.remove(buttonRef.current);
		};
	}, []);

	const mouseOver = () => {
		CustomEvents.dispatch(Events.HIGHLIGHTCURSOR, {});
	};

	const mouseOut = () => {
		CustomEvents.dispatch(Events.STANDARDCURSOR);
	};

	return (
		<button onClick={onClick} ref={buttonRef}>
			{children}
		</button>
	);
};

export default SimpleButton;
