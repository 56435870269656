import React, { useContext } from 'react';
import styled from 'styled-components';
import { Colors, Fonts, Breakpoints } from '../../style/Variables';
import context from '../../core/context';
import Link from '../../core/PageSwitch/Link';
import HTML from '../../core/utils/HTML';
import Functions from '../../style/Functions';

const SocialLink = styled(Link)`
	display: inline-block;
	margin: 0px 5px;
	z-index: 1;
	position: relative;
	:first-of-type {
		margin-left: 0px;
	}
	:last-of-type {
		margin-right: 0px;
	}
	${Functions.slideInHover(Colors.yellow)}

	&:focus,
	:hover {
		color: ${Colors.black};
	}
`;

const FooterBanner = styled.div`
	height: auto;
	font-family: ${Fonts.monoFamilyLight};
	font-size: ${Fonts.small};
	display: flex;
	flex-direction: column;
	white-space: pre-line;
	justify-content: flex-end
	align-items: flex-end
	flex-direction: column;
	gap: 20px;
	padding: 0 var(--gridMargin);
	${Functions.breakpoint(Breakpoints.tablet)} {
		font-size: var(--fontDefaultSize);
		justify-content: space-between;
		flex-direction: row;
		justify-items: flex-end;
		align-items: flex-end;
		padding-bottom: 80px;
	}
`;

const Location = styled.div<{ shown: Boolean }>`
	text-transform: capitalize;
	align-self: flex-start;
	text-align: start;
	line-height: 19.6px;
	p {
		margin: 0;
	}
	${Functions.breakpoint(Breakpoints.tablet)} {
		align-self: flex-end;
		text-align: left;
		display: block;
	}
`;

const OpeningHours = styled.div<{ shown: Boolean }>`
	display: block;
	align-self: flex-start;
	text-align: start;
	line-height: 22.4px;
	${Functions.breakpoint(Breakpoints.tablet)} {
		align-self: flex-end;
		text-align: initial;
	}
	span {
		text-transform: capitalize;
		text-decoration: underline;
		${Functions.breakpoint(Breakpoints.tablet)} {
			/* display: initial; */
		}
	}
`;

const Contact = styled.div<{ shown: Boolean }>`
	display: block;
	align-self: flex-start;
	text-align: start;
	line-height: 22.4px;
	${Functions.breakpoint(Breakpoints.tablet)} {
		align-self: flex-end;
		text-align: initial;
	}
	${Functions.slideInHover(Colors.yellow)}

	:hover {
		color: ${Colors.black};
	}
	span {
		text-transform: capitalize;
		text-decoration: underline;
		${Functions.breakpoint(Breakpoints.tablet)} {
			/* display: initial; */
		}
	}
`;

const Socials = styled.div`
	display: block;
	align-self: flex-start;
	text-align: start;
	line-height: 22.4px;
	${Functions.breakpoint(Breakpoints.tablet)} {
		align-self: flex-end;
		text-align: initial;
	}
	span {
		text-transform: capitalize;
		/* display: none; */
		${Functions.breakpoint(Breakpoints.tablet)} {
			/* display: initial; */
		}
	}
`;

const SocialMedias = styled.div`
	margin: 0px;
	line-height: 22.4px;
	display: flex;
	justify-content: flex-start;
	${Functions.breakpoint(Breakpoints.tablet)} {
		display: flex;
		justify-content: initial;
	}
`;

const Slash = styled.span`
	display: initial;

	/* display: none;
	${Functions.breakpoint(Breakpoints.tablet)} {
		display: initial;
	} */
`;

const Copyright = styled.div`
	position: relative;
	margin-left: 0px;
	font-size: var(--fontUltraSmall);
	font-family: ${Fonts.defaultFamily};
	font-weight: 400;
	bottom: 0px;
	text-align: center;
	${Functions.breakpoint(Breakpoints.tablet)} {
		text-align: initial;
		margin-left: 40px;
		position: absolute;
		margin-bottom: 38px;
	}
`;

const Elaborated = styled.div`
	display: flex;
`;

const Text = styled.div``;

export default function FooterInformation(props: { longFormat?: boolean; className?: string; copyright?: boolean }) {
	const { state } = useContext(context);
	return (
		<FooterBanner className={props.className}>
			<Location
				shown={props.longFormat}
				dangerouslySetInnerHTML={{ __html: HTML.clean(state.footer.address) }}
			></Location>
			<Contact shown={props.longFormat}>
				<Text>
					<a href={`mailto:${state.footer.contact}`}>{state.footer.contact}</a>
				</Text>
			</Contact>

			<SocialLink data={state.global.cookiePolicy}>
				<Text>{state.global.cookiePolicy.title}</Text>
			</SocialLink>
			<Socials>
				<SocialMedias>
					<SocialLink to={state.global.linkedin}>
						<Text>LinkedIn</Text>
					</SocialLink>
					<Slash>/</Slash>
					<SocialLink to={state.global.facebook}>
						<Text>Facebook</Text>
					</SocialLink>
					<Slash>/</Slash>
					<SocialLink to={state.global.instagram}>
						<Text>Instagram</Text>
					</SocialLink>
				</SocialMedias>
			</Socials>
			{/* {props.copyright === true && <Copyright>© Kunsthal Spritten 2022. All Rights Reserved</Copyright>} */}
		</FooterBanner>
	);
}
