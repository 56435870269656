import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

export interface ImageData {
	height: number;
	width: number;
	smartTags: Array<string>;
	tags: Array<string>;
	isImage: boolean;
	mimeType: string;
	format: string;
	url: string;
	defaultFieldMetadata: {
		alt: string;
		focalPoint: string;
		title: string;
	};
}

export interface ImageProps {
	data?: ImageData;
	alt?: string;
	title?: string;
	src?: string;
	cache?: string;
	width?: number | string;
	height?: number | string;
	minWidth?: number;
	maxWidth?: number;
	className?: string;
}

export const ImgWrapper = styled.div<{ height: number | string; width: number | string }>`
	position: relative;
	height: ${props => (props.height ? props.height : '100%')};
	width: ${props => (props.width ? props.width : '100%')};
	display: flex;

	img {
		height: ${props => (props.height ? props.height : '100%')};
		width: ${props => (props.width ? props.width : '100%')};
		object-fit: cover;
	}
`;

export default function Image(props: ImageProps) {
	// var img = useRef<HTMLIFrameElement>();

	// useEffect(() => {}, []);

	var src = props.src ? props.src : props.data ? props.data.url : null;
	var srcSet: string = null;

	if (!src) return <ImgWrapper height={props.height} width={props.width} className={props.className}></ImgWrapper>;

	if (src.includes('datocms-assets')) {
		let url = new URL(src);
		let search = url.searchParams;
		search.set('q', '70');
		search.set('fm', 'jpg');
		if (props.cache) search.set('cache', props.cache);
		if (props.width && props.height) {
			search.set('h', `${parseInt(props.height as string)}`);
			search.set('w', `${parseInt(props.width as string)}`);
		}

		// if(props.data && props.data.defaultFieldMetadata.focalPoint) {
		// } else {
		// }
		search.set('fit', 'crop');
		search.set('crop', 'faces,center');
		src = url.toString();

		let maxWidth = props.maxWidth
			? props.maxWidth
			: props.data && props.data.width
				? props.data.width
				: props.width
					? parseInt(props.width as string)
					: 1200;
		let minWidth = props.minWidth || 150;

		if (minWidth < maxWidth) {
			search.delete('h');
			search.delete('w');
			let loops = 6;
			let jump = (maxWidth - minWidth) / loops;
			let base = url.toString();
			srcSet = '';
			for (let i = 0; i <= loops; i++) {
				let width = Math.round(minWidth + jump * i);
				srcSet += `${base}&w=${width} ${width}w${i < loops ? ', ' : ''}`;
			}
		}
	}

	return (
		<ImgWrapper
			height={props.height ? props.height : null}
			width={props.width ? props.width : null}
			className={props.className}
		>
			<img
				height={props.height ? props.height : null}
				width={props.width ? props.width : null}
				alt={props.alt ? props.alt : props.data ? props.data.defaultFieldMetadata.alt : null}
				title={props.title ? props.title : props.data ? props.data.defaultFieldMetadata.title : null}
				src={src}
				srcSet={srcSet}
				loading='lazy'
			/>
		</ImgWrapper>
	);
}
