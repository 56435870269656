import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import DraggableCarousel, { ImperativeDraggableCarousel } from './Carousel/DraggableCarousel';
import HeroFooter from './HeroComponent/HeroFooter';
import Image, { ImageData } from './Image';
import { VideoData } from './VideoComponent';

const ImageGallery = styled.div`
	width: calc(100% + var(--gridMargin) * 2);
	height: 100vh;
	position: relative;
`;

const ImageContainer = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
`;

const StyledDraggableCarousel = styled(DraggableCarousel)`
	overflow: hidden;
`;

const CarouselItem = styled.div`
	position: relative;
	width: 100%;
	height: 100vh;
	flex: 1 0 100%;
`;

const StyledImage = styled(Image)`
	position: relative;
	width: 100%;
	height: 100vh;
	flex: 1 0 100%;
`;

const ThumbnailVideo = styled.video`
	position: relative;
	width: 100%;
	height: 100vh;
	object-fit: cover;
	flex: 1 0 100%;
`;

const BackDrop = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
`;

export default function RevealHero({
	data,
}: {
	data: { content: any[]; imageGallery: ImageData[]; video: VideoData };
}) {
	const carouselRef = useRef<ImperativeDraggableCarousel>(null);
	const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
	const [active, setActive] = useState(0);
	const [autoPlay, setAutoPlay] = useState(true);

	const content = data?.content;

	useEffect(() => {
		const currentVideoRef = videoRefs.current[active];
		if (currentVideoRef) {
			currentVideoRef.currentTime = 0; // Reset to start
			currentVideoRef.play();
		}
	}, [active]);

	const getCarouselUI = () =>
		content.map((item, index) => (
			<CarouselItem key={index}>
				{item.video ? (
					<ThumbnailVideo ref={el => (videoRefs.current[index] = el)} src={item.video.url} loop muted playsInline />
				) : (
					<StyledImage src={item.image.url} />
				)}
				<BackDrop />
			</CarouselItem>
		));

	return (
		<ImageGallery>
			<ImageContainer id='heroimage'>
				<StyledDraggableCarousel
					autoPlay={autoPlay}
					hideGap
					ref={carouselRef}
					activeIndex={active}
					setAutoPlay={setAutoPlay}
					dragToNewIndex={setActive}
					autoPlayTime={10000}
				>
					{getCarouselUI()}
				</StyledDraggableCarousel>
				<HeroFooter
					next={() => carouselRef.current?.nextSlide()}
					previous={() => carouselRef.current?.prevSlide()}
					activeContent={content[active]}
					active={active + 1}
					total={content.length}
				/>
			</ImageContainer>
		</ImageGallery>
	);
}
