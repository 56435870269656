import React from 'react';
import styled from 'styled-components';
import Functions from '../style/Functions';
import { Breakpoints } from '../style/Variables';

const ImageContainer = styled.div`
	width: 36px;
	height: 36px;
	object-fit: contain;
	${Functions.breakpoint(Breakpoints.mobile)} {
		width: 50px;
		height: 50px;
	}
`;

const LogoViewer = () => {
	const [isMobile, setIsMobile] = React.useState(false);
	const size = isMobile ? 36 : 50;
	const movePadding = isMobile ? 7.2 : 10; // Scaled proportionally for mobile
	const defaultCenter = size / 2;
	const [center, setCenter] = React.useState({ x: defaultCenter, y: defaultCenter });

	React.useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 690);
		};

		const handleMouseMove = (event: MouseEvent) => {
			const relativeX = event.clientX / window.innerWidth;
			const relativeY = event.clientY / window.innerHeight;

			const moveAmount = size - movePadding * 2;
			const newX = movePadding + moveAmount * relativeX;
			const newY = movePadding + moveAmount * relativeY;

			setCenter({ x: newX, y: newY });
		};

		window.addEventListener('mousemove', handleMouseMove);
		window.addEventListener('resize', handleResize);
		handleResize();

		return () => {
			window.removeEventListener('mousemove', handleMouseMove);
			window.removeEventListener('resize', handleResize);
		};
	}, [size, movePadding]);

	return (
		<ImageContainer>
			<svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d={`M0 ${size * 0.3}L${center.x} ${center.y}L0 ${size * 0.7}Z`} fill='black' />
				<path d={`M${size * 0.3} 0L${center.x} ${center.y}L${size * 0.7} 0Z`} fill='black' />
				<path d={`M${size} ${size * 0.3}L${center.x} ${center.y}L${size} ${size * 0.7}Z`} fill='black' />
				<path d={`M${size * 0.3} ${size}L${center.x} ${center.y}L${size * 0.7} ${size}Z`} fill='black' />
			</svg>
		</ImageContainer>
	);
};

export default LogoViewer;
