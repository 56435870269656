import React, { forwardRef, useEffect, useImperativeHandle, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import Functions from '../../style/Functions';
import gsap, { Quart } from 'gsap';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import { Index } from '../../style/Variables';

const Container = styled.div`
	height: fit-content;
	width: 100%;
	position: relative;
	overflow-x: hidden;
	overflow-y: hidden;
	z-index: ${Index.mobileImageCarousel};
	${Functions.breakpoint('tablet')} {
		overflow-x: hidden;
	}
`;

const Inner = styled.div<{ hideGap?: boolean }>`
	position: relative;
	left: 0;
	display: flex;
	gap: ${props => (props.hideGap ? 0 : 'var(--gridGutter)')};
	will-change: transform;
`;

export interface ImperativeDraggableCarousel {
	triggerHandleResize: () => void;
	nextSlide: () => void;
	prevSlide: () => void;
}

function DraggableCarousel(
	{
		children,
		className,
		autoPlay = false,
		activeIndex,
		hideGap,
		autoPlayTime = 5000,
		dragToNewIndex,
		setAutoPlay,
	}: {
		children: Array<React.ReactNode>;
		autoPlay?: boolean;
		hideGap?: boolean;
		className?: string;
		activeIndex: number;
		autoPlayTime?: number;
		setAutoPlay?: (autoPlay: boolean) => void;
		dragToNewIndex: (index: number) => void;
	},
	ref: React.ForwardedRef<ImperativeDraggableCarousel>
) {
	const carouselContainer = useRef<HTMLDivElement>(null);
	const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
	const [currentIndex, setCurrentIndex] = useState(activeIndex || 0);

	const draggableRef = useRef<any>(null);

	const calcElementWidth = () => {
		const element = carouselContainer.current?.firstElementChild as HTMLElement;

		if (!element) {
			console.warn('Element not found in the carousel container');
			return 0;
		}

		if (hideGap) {
			return element.clientWidth;
		}

		const gap = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--gridGutter'), 10) || 0;
		return element.clientWidth + gap;
	};

	const calcMaxBounds = () => {
		const elementWidth = calcElementWidth();
		const totalWidth = elementWidth * children.length;
		const containerWidth = carouselContainer.current?.offsetWidth || 0;

		const minX = Math.min(containerWidth - totalWidth, 0);
		const maxX = 0;

		return { minX, maxX, minY: 0, maxY: 0 };
	};

	const calcSnapPoints = () => {
		const elementWidth = calcElementWidth();
		return Array.from({ length: children.length }, (_, index) => index * -elementWidth);
	};

	const moveToIndex = (index: number, duration: number = 1.2) => {
		if (!carouselContainer.current) return;

		gsap.to(carouselContainer.current, {
			x: calcSnapPoints()[index],
			duration,
			ease: Quart.easeInOut,
		});
	};

	const calcNewIndex = (x: number) => {
		const snapPoints = calcSnapPoints();
		return snapPoints.reduce(
			(prevIndex, snapPoint, currentIndex) =>
				Math.abs(x - snapPoint) < Math.abs(x - snapPoints[prevIndex]) ? currentIndex : prevIndex,
			0
		);
	};

	const updateIndex = (newIndex: number) => {
		const childrenCount = React.Children.count(children);

		newIndex = (newIndex + childrenCount) % childrenCount;

		setCurrentIndex(newIndex);
		dragToNewIndex(newIndex);
		return newIndex;
	};

	const handleNextSlide = useCallback(() => {
		let nextIndex = (activeIndex + 1) % children.length;
		setCurrentIndex(nextIndex);
		moveToIndex(nextIndex);
		dragToNewIndex(nextIndex);
	}, [activeIndex, children.length]);

	const handlePrevSlide = useCallback(() => {
		let prevIndex = (activeIndex - 1 + children.length) % children.length;
		setCurrentIndex(prevIndex);
		dragToNewIndex(prevIndex);
		moveToIndex(prevIndex);
	}, [activeIndex, children.length]);

	const startAutoSlide = () => {
		const id = setInterval(() => {
			setCurrentIndex(prevIndex => {
				const index = updateIndex(prevIndex + 1);
				moveToIndex(index);
				return index;
			});
		}, autoPlayTime);
		setIntervalId(id);
	};

	const stopAutoSlide = () => {
		setAutoPlay(false);
		if (intervalId) {
			clearInterval(intervalId);
		}
	};

	const initDraggable = () => {
		gsap.registerPlugin(Draggable, InertiaPlugin);

		draggableRef.current = Draggable.create(carouselContainer.current, {
			type: 'x',
			initial: true,
			bounds: calcMaxBounds(),
			throwProps: true,
			throwResistance: 1000,
			edgeResistance: 1,

			snap: x => {
				const snapPoints = calcSnapPoints();
				return snapPoints.reduce((prev, curr) => (Math.abs(curr - x) < Math.abs(prev - x) ? curr : prev));
			},
			onPress: function () {
				stopAutoSlide();
			},
			onDragStart: function () {
				stopAutoSlide();
			},
			onDrag: function () {
				const newIndex = calcNewIndex(this.x);
				updateIndex(newIndex);
			},
			onThrowUpdate: function () {
				const newIndex = calcNewIndex(this.x);
				updateIndex(newIndex);
			},
		});
	};

	const handleResize = useCallback(() => {
		draggableRef.current[0].applyBounds(calcMaxBounds());
		const index = calcNewIndex(draggableRef.current[0].x);
		gsap.killTweensOf(carouselContainer.current);
		moveToIndex(index, 0);
	}, [activeIndex, initDraggable]);

	useImperativeHandle(ref, () => ({
		triggerHandleResize() {
			handleResize();
		},
		nextSlide() {
			stopAutoSlide();
			handleNextSlide();
		},
		prevSlide() {
			stopAutoSlide();
			handlePrevSlide();
		},
	}));

	useEffect(() => {
		initDraggable();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		if (autoPlay) {
			startAutoSlide();
		} else {
			stopAutoSlide();
		}
		return () => {
			stopAutoSlide();
		};
	}, [autoPlay]);

	// Update position when activeIndex changes
	useEffect(() => {
		setCurrentIndex(activeIndex);
	}, [activeIndex]);

	return (
		<Container className={className}>
			<Inner hideGap={hideGap} ref={carouselContainer}>
				{React.Children.map(children, child => {
					return React.cloneElement(child as React.ReactElement, {});
				})}
			</Inner>
		</Container>
	);
}

export default forwardRef(DraggableCarousel);
